require('./bootstrap');

import Vue from 'vue'
import VueRouter from 'vue-router';

import Axios from 'axios';
import router from './Router/index'
import store from './Store/index';
import App from './App.vue'
import Config from './config';

import vuetify from './vuetify';

const token = localStorage.getItem('token')




Vue.prototype.$axios = Axios;
Vue.prototype.config = Config;
Vue.prototype.$axios.interceptors.request.use(function (config) {

    // Do something before request is sent
    config.url = Config.endpoint + '/' + config.url
    // console.log('axios request', config.url)
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

Vue.prototype.$axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if(response.status === 401) {
        router.push({ name: "home" })
   }
    return response;
  }, function (error) {

    if(error.response.status === 401) {
        router.push({ name: "home" })
   }

    // router.push({ name: "home" })

    return Promise.reject(error);
  });



if (token) {
    Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'bearer '+token
  }

Vue.use(VueRouter)

const app = new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  components: { App }
});

