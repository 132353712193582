import Vue from 'vue';
import Vuex from 'vuex'
import axios from 'axios';
import Config from '../config';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        count: 0,
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
        status: '',
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        update_user(state){
            state.user = JSON.parse(localStorage.getItem('user'))

        },
        auth_success(state, token, user) {

            state.status = 'success'
            state.token = localStorage.getItem('token')
            state.user = JSON.parse(localStorage.getItem('user'))
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.user = null
        },
    },
    actions: {
        update_user({ commit }){
            commit('update_user');
        },
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({ url: 'login', data: user, method: 'POST' })
                    .then(resp => {

                        const token = resp.data.access_token
                        const user = resp.data.user

                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(user))
                        axios.defaults.headers.common['Authorization'] = 'bearer ' + token

                        commit('auth_success', token, resp.data.user)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.clear();

                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        user: (state) => state.user
    },
})

export default store;
