<template>
  <v-app app>
    <Navbar></Navbar>
    <v-main>
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>

<script>
import "./Styles/app.scss";

export default {
  name: "app",
  components: {
    Navbar: () => import("./Components/Navbar.vue"),
  },
};
</script>

