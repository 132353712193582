const routes = [{
        path: '/',
        component: () => import('../Pages/Home.vue'),
        name: 'home'
    },
    {
        path: '/about',
        component: () => import('../Pages/About.vue'),
        name: 'about'
    },
    {
        path: '/sales',
        component: () => import('../Pages/SalesAll.vue'),
        name: 'sales',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customers',
        component: () => import('../Pages/Customers.vue'),
        name: 'customers',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/register',
        component: () => import('../Pages/Register.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../Pages/Dashboard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add-vehicle',
        component: () => import('../Pages/AddVehicle.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company',
        component: () => import('../Pages/Company.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sale',
        component: () => import('../Pages/Sales.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sales/edit/:id',
        component: () => import('../Pages/SalesEdit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/inventory',
        component: () => import('../Pages/Inventory.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/vehicle/:id',
        component: () => import('../Pages/EditVehicle.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/taxes',
        component: () => import('../Pages/AllStates.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '*',
        component: () => import('../Pages/NotFound.vue')
    }
]

export default routes;
